import styles from '../styles/Products.module.css'
import globalStyles from '../styles/Home.module.css'
import { Fade, Slide } from 'react-awesome-reveal';

const Products = () => {

    return (
        <section id='products' className={styles.productsContainer}>
            <div className={styles.productsOverlay}></div>
            <Fade duration={2000} triggerOnce>
                <div className={styles.productsBg}></div>
            </Fade>
            <div className={globalStyles.contentContainer}>
                <h1 className={styles.productsTitle}>Наши проекты</h1>
                    <div className={styles.productsCards}>
                        <Slide direction='left' duration={1500} triggerOnce>
                            <div className={styles.productsCard}>
                                <div className={styles.productsCardLeft}>
                                    <div className={styles.productsCardLeftOverlay}></div>
                                    <img src={require('../images/icons/Products/ai.png')} alt="" />
                                </div>
                                <div className={styles.productsCardRight}>
                                    <h2>
                                        Интеллектуальная система видеонаблюдения с ИИ-аналитикой
                                    </h2>
                                    <p>
                                        Решение в области объектовой безопасности, интегрирующее глубокое 
                                        обучение для распознавания и анализа видеоданных в реальном времени.
                                    </p>
                                </div>
                            </div>
                        </Slide>
                        <Slide direction='right' duration={1500} triggerOnce>
                            <div className={styles.productsCard}>
                                <div className={styles.productsCardLeft}>
                                    <div className={styles.productsCardLeftOverlay}></div>
                                    <img src={require('../images/icons/Products/app-development.png')} alt="" />
                                </div>
                                <div className={styles.productsCardRight}>
                                    <h2>
                                        Персонализированный Веб-интерфейс для системы видеонаблюдения
                                    </h2>
                                    <p>
                                        Доступ к функциям управления и мониторинга видеонаблюдения 
                                        для пользователей, предоставляемый провайдером услуг. 
                                    </p>
                                </div>
                            </div>
                        </Slide>
                        <Slide direction='left' duration={1500} triggerOnce>
                            <div className={styles.productsCard}>
                                <div className={styles.productsCardLeft}>
                                    <div className={styles.productsCardLeftOverlay}></div>
                                    <img src={require('../images/icons/Products/automation.png')} alt="" />
                                </div>
                                <div className={styles.productsCardRight}>
                                    <h2>
                                        Автоматизированная система управления процессом армирования акриловых ванн
                                    </h2>
                                    <p>
                                        Инновационное решение для задач оптимизации и контроля 
                                        производственного процесса армирования.
                                    </p>
                                </div>
                            </div>
                        </Slide>
                        <Slide direction='right' duration={1500} triggerOnce>
                            <div className={styles.productsCard}>
                                <div className={styles.productsCardLeft}>
                                    <div className={styles.productsCardLeftOverlay}></div>
                                    <img src={require('../images/icons/Products/electronic.png')} alt="" />
                                </div>
                                <div className={styles.productsCardRight}>
                                    <h2>
                                        Многоканальная платформа SDR
                                    </h2>
                                    <p>
                                        Гибкая и масштабируемая SDR-система, поддерживающая 
                                        обработку множественных радиочастотных сигналов параллельно.
                                    </p>
                                </div>
                            </div>
                        </Slide>
                    </div>
            </div>
        </section>
    );
}
 
export default Products;