import styles from '../styles/Services.module.css'
import globalStyles from '../styles/Home.module.css'
import { Fade } from 'react-awesome-reveal';

const Services = () => {

    return (
        <section id='services' className={styles.servicesContainer}>
            <div className={styles.servicesContentWrapper}>
                <div className={globalStyles.contentContainer}>
                    <Fade triggerOnce duration={1000}>
                            <h1 className={styles.servicesTitle}>Комплексные решения для высокотехнологичных проектов</h1>
                            <h2 className={styles.servicesSubtitle}>
                                Мы предоставляем полный цикл разработки отечественных устройств — от формирования технических 
                                требований до интеграции и поддержки готового решения. Наша команда разрабатывает архитектуру 
                                и реализует IP-блоки любой сложности, обеспечивая высокую производительность и надежность.
                            </h2>
                            <div className={styles.servicesCards}>
                                <div id='serviceCard-1' className={`${styles.servicesCard}`}>
                                    <h2 className={styles.servicesCardTitle}>Разработка SDR платформ</h2>
                                    <p className={styles.servicesCardContent}>
                                        Создание многоканальных высокопроизводительных SDR-платформ и программного 
                                        обеспечения для них, интеграция разработанных платформ в ваши решения.
                                        Разработка ПО для сторонних SDR</p>
                                </div>
                                <div id='serviceCard-2' className={styles.servicesCard}>
                                    <h2 className={styles.servicesCardTitle}>Специализированные программно-аппаратные комплексы</h2>
                                    <p className={styles.servicesCardContent}>
                                        Разработка систем, адаптированных под уникальные требования заказчика
                                    </p>
                                </div>
                                <div id='serviceCard-3' className={styles.servicesCard}>
                                    <h2 className={styles.servicesCardTitle}>Интеграция сторонних решений</h2>
                                    <p className={styles.servicesCardContent}>
                                        Адаптация и поддержка IP-блоков сторонних разработчиков 
                                        для расширения функциональности ваших систем
                                    </p>
                                </div>
                                <div id='serviceCard-4' className={styles.servicesCard}>
                                    <h2 className={styles.servicesCardTitle}>Платформы Intel(Altera) и AMD(Xilinx)</h2>
                                    <p className={styles.servicesCardContent}>
                                        Опыт работы с ведущими платформами для 
                                        создания высококачественных электронных устройств
                                    </p>
                                </div>
                                <div id='serviceCard-5' className={styles.servicesCard}>
                                    <h2 className={styles.servicesCardTitle}>Интерфейсы высокой пропускной способности</h2>
                                    <p className={styles.servicesCardContent}>
                                        Реализация современных интерфейсов (Ethernet 10G/40G, PCI-Express 3.0, JESD B, CPRI, USB 3.0) 
                                        для обеспечения высочайшей скорости передачи данных
                                    </p>
                                </div>
                                <div id='serviceCard-6' className={styles.servicesCard}>
                                    <h2 className={styles.servicesCardTitle}>Проектирование встраиваемых систем</h2>
                                    <p className={styles.servicesCardContent}>
                                        Разработка программных решений с использованием RTOS и Embedded Linux для 
                                        максимальной надежности и производительности
                                    </p>
                                </div>
                            </div>
                            <h1 className={styles.servicesTitle}>Разработка ПО</h1>
                            <h2 className={styles.servicesSubtitle}>
                                Мы создаем передовые охранные системы видеонаблюдения с искусственным интеллектом, которые обеспечивают высочайший уровень 
                                безопасности для крупных объектов. Наши решения предназначены для защиты как муниципальных зданий, 
                                так и промышленных комплексов, где безопасность — это не просто задача, а приоритет.
                            </h2>
                            <div id='software' className={styles.servicesCards}>
                                <div id='serviceCard-7' className={`${styles.servicesCard}`}>
                                    <h2 className={styles.servicesCardTitle}>Интеллектуальные системы видеонаблюдения для защиты больших объектов</h2>
                                    <p className={styles.servicesCardContent}>
                                        Мы создаем интеллектуальные охранные системы видеонаблюдения, которые обеспечивают надежную защиту крупных объектов, 
                                        используя новейшие технологии искусственного интеллекта. 
                                    </p>
                                </div>
                                <div id='serviceCard-8' className={styles.servicesCard}>
                                    <h2 className={styles.servicesCardTitle}>Разработка веб-приложений</h2>
                                    <p className={styles.servicesCardContent}>
                                        Мы создаем веб-приложения, используя передовые технологии, полностью адаптированные под ваши задачи. Мы разрабатываем гибкие и масштабируемые системы, которые легко справляются с высокой 
                                        нагрузкой и обеспечивают надежность в любой ситуации.
                                    </p>
                                </div>
                                <div id='serviceCard-9' className={styles.servicesCard}>
                                    <h2 className={styles.servicesCardTitle}>Разработка десктопных приложений для различных операционных систем </h2>
                                    <p className={styles.servicesCardContent}>
                                    Мы разрабатываем мощные десктопные приложения для Windows, Linux и Mac OS. Наши решения поддерживают обработку большого количества потоков данных в реальном времени, что идеально подходит для интеллектуальных систем видеонаблюдения.
                                    </p>
                                </div>
                            </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
}
 
export default Services;