import { Link } from 'react-scroll';
import styles from '../../styles/Header/Nav.module.css'

const Nav = () => {
    return (
        <nav id='nav' className={styles.headerNav}>
            <Link to="nav" className={styles.navLogo}><img src={require('../../images/logo.png')} alt="" /></Link>
            <Link to='about' className={styles.navLink}>О нас</Link>
            <Link to='services' className={styles.navLink}>Услуги</Link>
            <Link to='products' className={styles.navLink}>Решения</Link>
            <Link to='contacts' className={styles.navLink}>Контакты</Link>
        </nav>
    );
}
 
export default Nav;