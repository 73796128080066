import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import { useEffect } from 'react';
import UnknownPage from './components/UnknownPage';

function App() {

  return (
    <>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/*' element={<UnknownPage></UnknownPage>}></Route>
      </Routes>
    </>
  );
}

export default App;
