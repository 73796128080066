import styles from '../styles/Modal.module.css';
import emailjs from '@emailjs/browser';
import { useState, useEffect, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';

const Modal = (props) => {

    var keys = {37: 1, 38: 1, 39: 1, 40: 1};

    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; } 
      }));
    } catch(e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const modalRef = useRef(null);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
          .send(
            'service_cfafiqg',
            'template_k8gbnvb',
            {
                message: `Имя: ${name}\nТелефон: ${phone}\nПочта: ${email}\nСообщение: ${message}`
            },
            {
            publicKey: 'XO7ya63NtKjhUeRvg',
          })
          .then(
            () => {
                alert('Ваше сообщение было отправлено!');
                props.setIsOpen(false);
            },
            (error) => {
                alert('Возникла ошибка при отправке сообщения.');
                console.log('FAILED...', error.text);
            },
          );
    }

    function preventDefault(e) {
        e.preventDefault();
      }
      
      function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
          preventDefault(e);
          return false;
        }
      }
    useEffect(() => {
        window.addEventListener('DOMMouseScroll', preventDefault, false);
        window.addEventListener(wheelEvent, preventDefault, wheelOpt);
        window.addEventListener('touchmove', preventDefault, wheelOpt); 
        window.addEventListener('keydown', preventDefaultForScrollKeys, false);
        return () => {
            window.removeEventListener('DOMMouseScroll', preventDefault, false);
            window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
            window.removeEventListener('touchmove', preventDefault, wheelOpt);
            window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
        }
    }, [])

    return (
        <div ref={modalRef} className={styles.modalWrapper}>
            <Fade>
                <div className={styles.modalWindow}>
                    <div className={styles.modalFormTopRow}>
                        <img className={styles.modalFormLogo} src={require('../images/logo-black.png')} alt="" />
                        <button className={styles.modalFormCloseButton} onClick={() => props.setIsOpen(false)}><img src={require('../images/icons/close-icon.png')}></img></button>
                    </div>
                    <h2 className={styles.modalFormTitle}>Оставьте нам сообщение</h2>
                    <h2 className={styles.modalFormTitle}>и мы свяжемся с вами</h2>
                    <form className={styles.modalForm} onSubmit={sendEmail}>
                        <div className={styles.modalFormInputs}>
                            <input type="text" onChange={(e) => setName(e.target.value)} placeholder="Ваше имя" required/>
                            <input type="tel" onChange={(e) => setPhone(e.target.value)} placeholder="Ваш номер телефона" required/>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Ваш e-mail" required/>
                            <textarea onChange={(e) => setMessage(e.target.value)} name="" id="" placeholder="Текст вашего сообщения"></textarea>
                            <input type="submit" value="Отправить"/>
                        </div>
                    </form>
                </div>
            </Fade>
        </div>
    );
}
 
export default Modal;