import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Header from "./Header/Header";
import Products from "./Products";
import Services from "./Services";
import Stack from "./Stack";
import Steps from "./Steps";
import Footer from "./Footer";
import ScrollButton from "./ScrollButton";
import { useEffect, useState } from "react";

const Home = () => {

    const [isShown, setIsShown] = useState(false);

    const handleScroll = (e) => {
        const scrollLength = window.scrollY;
        if (scrollLength > 1000) {
            setIsShown(true);
        } else {
            setIsShown(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    return ( 
        <>
            {isShown && <ScrollButton></ScrollButton>}
            <Header></Header>
            <AboutUs></AboutUs>
            <Services></Services>
            <Products></Products>
            <Stack></Stack>
            <Steps></Steps>
            <ContactUs></ContactUs>
            <Footer></Footer>
        </>
    );
}
 
export default Home;