import { Fade } from 'react-awesome-reveal';
import styles from '../styles/ContactUs.module.css';
import globalStyles from '../styles/Home.module.css';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

const ContactUs = () => {

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
          .send(
            'service_yenj0iu',
            'template_k8gbnvb',
            {
                message: `Имя: ${name}\nТелефон: ${phone}\nПочта: ${email}\nСообщение: ${message}`
            },
            {
            publicKey: 'XO7ya63NtKjhUeRvg',
          })
          .then(
            () => {
                alert('Ваше сообщение было отправлено!');
            },
            (error) => {
                alert('Возникла ошибка при отправке сообщения.');
                console.log('FAILED...', error.text);
            },
          );
    }

    return (
        <section id='contacts' className={styles.contactUsContainer}>
            <div className={globalStyles.contentContainer}>
                <Fade triggerOnce duration={1000}>
                    <div className={styles.contactUsContentWrapper}>
                        <div className={styles.contactUsContentLeft}>
                            <h1 className={styles.contactUsTitle}>Напишите нам</h1>
                            <h2 className={styles.contactUsSubtitle}>Оставьте нам сообщение или задайте вопрос,<br/> и мы свяжемся с вами</h2>
                            <img src={require('../images/blobs.png')} alt="" />
                        </div>
                        <div className={styles.contactUsContentRight}>
                            <form className={styles.contactUsForm} onSubmit={sendEmail}>
                                <input type="text" placeholder='Ваше имя' onChange={setName} required/>
                                <input type='tel' placeholder='Ваш телефон' onChange={setPhone} required/>
                                <input type="email" placeholder='Ваш e-mail' onChange={setEmail} required/>
                                <textarea name="messagebox" id="" placeholder='Текст вашего сообщения' onChange={setMessage}></textarea>
                                <input type="submit"  value="Отправить"/>
                            </form>
                        </div>
                    </div>
                </Fade>
            </div>
        </section>
    );
}
 
export default ContactUs;