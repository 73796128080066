import styles from '../styles/Steps.module.css';
import globalStyles from '../styles/Home.module.css';
import { ReactComponent as StepsSVG_1 } from '../images/steps_1.svg'
import { ReactComponent as StepsSVG_2 } from '../images/steps_2.svg';
import { ReactComponent as StepsSVG_3 } from '../images/steps_3.svg'
import { ReactComponent as StepsSVG_4 } from '../images/steps_4.svg'; 
import { Fade } from 'react-awesome-reveal';
import video from '../videos/steps-bg_animation(2).mp4';

const Steps = () => {

    return (
        <section id='steps' className={styles.stepsSectionContainer}>
            <div className={styles.stepsSectionOverlay}></div>
            <video className={styles.stepsSectionBgVideo} src={video} muted loop autoPlay></video>
            <div className={globalStyles.contentContainer}>
                <div className={styles.stepsSectionContentWrapper}>
                    <Fade triggerOnce duration={1000}>
                        <div className={styles.stepsSectionTitle}>Этапы работы</div>
                        <div className={styles.stepsSectionSVGContainer}>
                            <StepsSVG_1 className={styles.stepsSectionSVG1}></StepsSVG_1>
                            <StepsSVG_2 className={styles.stepsSectionSVG2}></StepsSVG_2>
                            <StepsSVG_3 className={styles.stepsSectionSVG3}></StepsSVG_3>
                            <StepsSVG_4 className={styles.stepsSectionSVG4}></StepsSVG_4>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
}
 
export default Steps;