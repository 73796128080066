import styles from '../styles/UnknownPage.module.css';
import { Link } from 'react-router-dom';


const UnknownPage = () => {
    return ( 
        <div className={styles.unknownPageContainer}>
            <h1 className={styles.unknownPageTitle}>Такой страницы не существует</h1>
            <Link to={'/'} className={styles.unknownPageReturnLink}>Вернуться на главную</Link>
        </div>
    );
}
 
export default UnknownPage;