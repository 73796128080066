import styles from '../../styles/Header/Header.module.css';
import globalStyles from '../../styles/Home.module.css'
import Nav from './Nav';
import FirstScreen from './FirstScreen';
import { Fade } from 'react-awesome-reveal';
import Modal from '../Modal';
import { useState } from 'react';

const Header = () => {

    const [isOpen, setIsOpen] = useState();

    return (
        <header className={styles.headerContainer}>
            {isOpen === true && <Modal setIsOpen={setIsOpen}></Modal>}
            <div className={globalStyles.contentContainer}>
                <Fade duration={1500} triggerOnce>
                    <Nav></Nav>
                    <FirstScreen setIsOpen={setIsOpen}></FirstScreen>
                </Fade>
            </div>
        </header>
    );
}
 
export default Header;