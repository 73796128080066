import styles from '../styles/Footer.module.css';
import globalStyles from '../styles/Home.module.css';
import externalStyles from '../styles/Services.module.css';
import { Link } from 'react-scroll';
import { YMaps, Map, Placemark, FullscreenControl } from '@pbe/react-yandex-maps';
import { Fade } from 'react-awesome-reveal';

const Footer = () => {

    const handleClick = (e) => {
        const id = e.target.id[e.target.id.length - 1];
        const card = document.getElementById(`serviceCard-${id}`);
        console.log(card);
        card.classList.add(`${externalStyles.servicesCardActive}`);
        console.log(card);
        setTimeout(() => {
            card.classList.remove(`${externalStyles.servicesCardActive}`);
            console.log(card);
        }, 1500)
    }

    return (
        <footer className={styles.footerContainer}>
            <div className={globalStyles.contentContainer}>
                <Fade duration={1000} triggerOnce>
                    <div className={styles.footerInfo}>
                        <div className={styles.footerLogoContainer}>
                            <img src={require('../images/logo.png')} alt="" />
                        </div>
                        <div className={styles.footerMainInfo}>
                            <div className={styles.footerMainInfoTop}>
                                <h2>Услуги</h2>
                                <div className={styles.footerMainInfoTopLinks}>
                                    <Link id='footerLink-1' to='serviceCard-1' offset={-400} onClick={handleClick} className={styles.footerLink}>Разработка SDR платформ</Link>
                                    <Link id='footerLink-6' to='serviceCard-6' offset={-400} onClick={handleClick} className={styles.footerLink}>Проектирование встраиваемых систем</Link>
                                    <Link id='footerLink-2' to='serviceCard-2' offset={-400} onClick={handleClick} className={styles.footerLink}>Специализированные программно-аппаратные комплексы</Link>
                                    <Link id='footerLink-4' to='serviceCard-4' offset={-400} onClick={handleClick} className={styles.footerLink}>Мультиплатформенные Решения</Link>
                                    <Link id='footerLink-3' to='serviceCard-3' offset={-400} onClick={handleClick} className={styles.footerLink}>Интеграция сторонних решений</Link>
                                    <Link id='footerLink-5' to='serviceCard-5' offset={-400} onClick={handleClick} className={styles.footerLink}>Интерфейсы высокой пропускной способности</Link>
                                    <Link id='footerLink-7' to='serviceCard-7' offset={-400} onClick={handleClick} className={styles.footerLink}>Интеллектуальные системы видеонаблюдения</Link>
                                    <Link id='footerLink-8' to='serviceCard-8' offset={-400} onClick={handleClick} className={styles.footerLink}>Разработка веб-приложений</Link>
                                    <Link id='footerLink-9' to='serviceCard-8' offset={-400} onClick={handleClick} className={styles.footerLink}>Разработка десктопных приложений</Link>
                                </div>
                            </div>
                            <div className={styles.footerMainInfoBottom}>
                                <div className={styles.footerMainInfoBottomColumn}>
                                    <h2>Контакты</h2>
                                    <div className={styles.footerMainInfoBottomRow}>
                                        <img src={require('../images/icons/Footer/map-pointer.png')} alt="" />
                                        <p>600000, г. Владимир, ул. Белоконской, д 3, 
                                        ауд. 425–2 (2-й корпус ВлГУ)</p>
                                    </div>
                                    <div className={styles.footerMainInfoBottomRow}>
                                        <img src={require('../images/icons/Footer/email.png')} alt="" />
                                        <p>dsp-club@yandex.ru</p>
                                    </div>
                                    <div className={styles.footerMainInfoBottomRow}>
                                        <img src={require('../images/icons/Footer/phone.png')} alt="" />
                                        <p>+7 (3922) 47-51-47</p>
                                    </div>
                                </div>
                                <div className={styles.footerMainInfoBottomColumn}>
                                    <h2>Юридический адрес ООО «Диаграмма»</h2>
                                    <div className={styles.footerMainInfoBottomRow}>
                                        <img src={require('../images/icons/Footer/map-pointer.png')} alt="" />
                                        <p>600005, г. Владимир, ул. Александра Матросова, д. 28б, помещение № 9, офис № 216</p>
                                    </div>
                                    <div className={styles.footerMainInfoBottomRow}>
                                        <p>ИНН: 3328021060</p>
                                    </div>
                                    <div className={styles.footerMainInfoBottomRow}>
                                        <p>КПП: 332801001</p>
                                    </div>
                                    <div className={styles.footerMainInfoBottomRow}>
                                        <p>ОГРН: 1183328010359</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footerMainInfoMap}>
                        <YMaps>
                            <Map defaultState={{ center: [56.145893, 40.373680], zoom: 16}} width={'100%'} height={'100%'}>
                                <Placemark geometry={[56.145982, 40.373749]}></Placemark>
                                <FullscreenControl></FullscreenControl>
                            </Map>
                        </YMaps>
                    </div>
                </Fade>
            </div>
        </footer>
    );
}
 
export default Footer;