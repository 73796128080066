import { Link } from "react-scroll";
import styles from '../styles/ScrollButton.module.css'

const ScrollButton = () => {
    return (
        <Link to="nav" className={styles.scrollButton}>
                <img src={require('../images/donw-arrow.png')} alt=""/>
            <div className={styles.scrollButtonImgOverlay}>
            </div>
        </Link>
    );
}
 
export default ScrollButton;