import styles from '../styles/Stack.module.css';
import globalStyles from '../styles/Home.module.css';
import { Fade } from 'react-awesome-reveal';
import video from '../videos/stack-bg2_animation.mp4';
import { useRef, useEffect } from 'react';

const Stack = () => {

    const videoRef = useRef(null);

    useEffect(() => {
        try {
            const browser = navigator.userAgent;
            if (!browser.toLocaleLowerCase().includes('firefox')) {
                const video = videoRef.current;
                video.playbackRate = 0.3;
                video.play();
            }
        } catch (error) {
            console.error(error.message);
        }
    }, [])

    return (
        <section id='stack' className={styles.stackContainer}>
            <div className={styles.stackOverlay}></div>
            <video ref={videoRef} className={styles.stackBgVideo} src={video} muted loop autoPlay></video>
            <div className={globalStyles.contentContainer}>
                <Fade triggerOnce duration={1000} style={{position: 'relative', zIndex: 3}}>    
                    <h1 className={styles.stackTitle}>Технологии</h1>
                    <div className={styles.stackRow}>
                        <h2 className={styles.stackRowTitle}>Backend</h2>
                        <div className={styles.stackRowContent}>
                            <p>СУБД: PostgreSQL, SQLite, mongoDB, cassandra, H2</p>
                            <p>Python и фреймфорки: FastAPI, gstreamer, OpenCV, Flask</p>
                            <p>Технологии котейнеризации: Docker, Docker Compose</p>
                        </div>
                    </div>
                    <div className={styles.stackRow}>
                        <h2 className={styles.stackRowTitle}>Frontend</h2>
                        <div className={styles.stackRowContent}>
                            <p>HTML/CSS/JS + React</p>
                            <p>C/C++ + Qt</p>
                        </div>
                    </div>
                    <div className={styles.stackRow}>
                        <h2 className={styles.stackRowTitle}>Языки описания аппаратуры</h2>
                        <div className={styles.stackRowContent}>
                            <p>VHDL/Verilog/SystemVerilog</p>
                        </div>
                    </div>
                    <div className={styles.stackRow}>
                        <h2 className={styles.stackRowTitle}>Моделирование</h2>
                        <div className={styles.stackRowContent}>
                            <p>Tool Command Line(TCL)</p>
                        </div>
                    </div>
                </Fade>
            </div>
        </section>
    );
}
 
export default Stack;