import styles from '../styles/AboutUs.module.css'
import globalStyles from '../styles/Home.module.css'
import { Zoom } from 'react-awesome-reveal';

const AboutUs = () => {
    return (
        <section id='about' className={styles.aboutUsContainer}>
            <div className={globalStyles.contentContainer}>
                <div className={styles.aboutUsTopInfo}>
                    <h1 className={styles.aboutUsTitle}>О компании</h1>
                    <p className={styles.aboutUsDescription}>
                        Ведущая компания в области телекоммуникаций, 
                        ООО «ДИАГРАММА», гордится своим более чем 20-летним опытом в разработке 
                        высококачественного телекоммуникационного оборудования и специализированного 
                        программного обеспечения. Наша команда состоит из высококвалифицированных 
                        специалистов с глубокими знаниями и образованием в области вычислительной 
                        техники и радиоэлектроники. 
                    </p>
                </div>
                    <Zoom triggerOnce duration={1000}>
                <div className={styles.aboutUsCards}>
                        <div className={styles.aboutUsCard}>
                            <img src={require('../images/icons/About/property.png')} alt="" />
                            <h3 className={styles.aboutUsCardTitle}>Отечественная продукция</h3>
                            <p className={styles.aboutUsCardInfo}>
                                Мы создаем высококачественные телекоммуникационные решения, 
                                полностью разработанные и произведенные нами
                            </p>
                        </div>
                        <div className={styles.aboutUsCard}>
                            <img src={require('../images/icons/About/verified.png')} alt="" />
                            <h3 className={styles.aboutUsCardTitle}>Безопасность</h3>
                            <p className={styles.aboutUsCardInfo}>
                                Мы уделяем особое внимание безопасности, 
                                предоставляя защищенные телекоммуникационные системы
                            </p>
                        </div>
                        <div className={styles.aboutUsCard}>
                            <img src={require('../images/icons/About/rating.png')} alt="" />
                            <h3 className={styles.aboutUsCardTitle}>Квалифицированные сотрудники</h3>
                            <p className={styles.aboutUsCardInfo}>
                                Наша команда закончила более 100 проектов в сфере систем автономного 
                                видеонаблюдения и телекоммуникационного оборудования
                            </p>
                        </div>
                        <div className={styles.aboutUsCard}>
                            <img src={require('../images/icons/About/developing.png')} alt="" />
                            <h3 className={styles.aboutUsCardTitle}>Полный цикл разработки</h3>
                            <p className={styles.aboutUsCardInfo}>
                                Мы обеспечиваем надежность и локальную поддержку 
                                на каждом этапе – от идеи до реализации 
                            </p>
                        </div>
                        <div className={styles.aboutUsCard}>
                            <img src={require('../images/icons/About/individual.png')} alt="" />
                            <h3 className={styles.aboutUsCardTitle}>Индивидуальный подход</h3>
                            <p className={styles.aboutUsCardInfo}>
                                С нуля создаём инновационные цифровые продукты, 
                                соответствующие вашим требованиям
                            </p>
                        </div>
                </div>
                    </Zoom>
            </div>
        </section>
    );
}
 
export default AboutUs;