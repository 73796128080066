import styles from '../../styles/Header/FirstScreen.module.css'
import { Link } from 'react-scroll';

const FirstScreen = (props) => {

    return (
        <>
            <div className={styles.firstScreenContainer}>
                    <h1 className={styles.firstScreenTitle}>
                        Диаграмма - экспертные решения для задач любой сложности в сфере телекоммуникаций
                    </h1>
                    <h2 className={styles.firstScreenSubtitle}>Мы предоставляем услуги разработки высококачественного телекоммуникационного оборудования и специализированного ПО</h2>
                    <div className={styles.firstScreenButtons}>
                        <button className={styles.firstScreenContactButton} onClick={() => props.setIsOpen(true)}>СВЯЗАТЬСЯ С НАМИ</button>
                        <Link className={styles.firstScreenContactButton} to='services'>ТЕЛЕКОММУНИКАЦИИ</Link>
                        <Link className={styles.firstScreenContactButton} to='software' offset={-400}>РАЗРАБОТКА ПО</Link>
                    </div>
                    <div className={styles.firstScreenImgWrapper}>
                        <img src={require('../../images/logo.png')} alt="" />
                    </div>
                </div>
        </>
    );
}
 
export default FirstScreen;